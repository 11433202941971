import React from 'react';
import Menu from '../layouts/Menu';
// import { NavLink } from "react-router-dom";

function Maintenance() {
    const handleClick = () => {
        // force a re-render
        this.forceUpdate();
      };
  return (
    <div>
        <Menu />
        <section className="inner-banner">
            <div className="container">
                <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                <h2 className="inner-banner__title">Jelajah Kanun</h2>
                {/* <ul className="list-unstyled thm-breadcrumb">
                    <li><NavLink onClick={handleClick} to="/">Utama</NavLink></li>
                    <li>Hubungi</li>
                </ul> */}
            </div>
        </section>
        <div className="col-lg-12 col-md-6 col-sm-12">
          
            <img src="assets/images/under_maintenance.png" alt="Awesome Image" className="center"/>
          
        </div>
    </div>
  )
}

export default Maintenance;