import React,{ useState, useEffect } from 'react';
// import logo from './logo.svg';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Cases from './pages/Cases';
import Contact from './pages/Contact';
import Maintenance from './pages/Maintenance';
import Service from './pages/Service';
import Error from './pages/Error';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import './App.css';
import AttorneyDetail from './pages/Attorney-detail';
import Hariz from './Teams/Hariz';
const $ = require("jquery");

const AppLayout = () => (
  <>
    <div className="preloader"><span><img src="assets/images/resources/hars2preloader150.png" alt="Awesome Image" /></span></div>
    <div className="page-wrapper">
      {/* <Header /> */}
      <Outlet />
      <Footer />
</div>
  </>
);

function App() {
  useEffect(() => {
    // if ($('.preloader').length) {
    //           $('.preloader').fadeOut('slow');
    //       }
        
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL +"/assets/js/theme.js";
    script.async = true;
    document.body.appendChild(script);
          
  }, []);
  return (
    // <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route element={<AppLayout />}>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/attorney' element={<AttorneyDetail />} />
          <Route path='/maintenance' element={<Maintenance />} />
          <Route path='/service' element={<Service />}/>
          <Route path='/contact' element={<Contact />} />
          <Route path='/cases' element={<Cases />} />
          <Route path='/about/hariz' element={<Hariz />} />
          <Route path='*' element={<Error />} />
          </Route>
        </Routes>
      </Router>
      
      
    // </div>
  );
}

export default App;
