import React,{ useState, useEffect } from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import $ from 'jquery';

// const handleClick = () => {
//     // force a re-render
//     this.forceUpdate();
//   };

function Header() {
    // useEffect(() => {
        const location =  useLocation();
        const {pathname} =location;
        const splitLocation = pathname.split("/");
        const handleClick = () => {
            // force a re-render
            this.forceUpdate();
          };
       const openMenu = () => {
        if ($('.main-navigation .navigation-box').length) {
            var subMenu = $('.navigation-box ul');
            subMenu.parent('li').children('Navlink').append(function() {
                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
            });
            var mainNavToggler = $('#main_MenuNav');
            var subNavToggler = $('.main-navigation .sub-nav-toggler');
            // mainNavToggler.click(
                // function () {
                var Self = $(this);
                var menu = Self.closest('.header-navigation').find(Self.data('target'));
                // var menu = document.getElementsByClassName("main-navigation");
                // console.log("Test");
                // $(menu).slideToggle();
                // $(menu).toggleClass('showen');
                $("#main_NavIgation").slideToggle();
               $("#main_NavIgation").toggleClass("showen");
                return false;
            // });
            // subNavToggler.on('click', function() {
            //     var Self = $(this);
            //     Self.parent().parent().children('ul').slideToggle();
            //     return false;
            // });
        }
    }   
    //   }, []);
    
  return (
    <div>
<div className="site-header__header-one-wrapper">
            <div className="topbar-one">
                <div className="container">
                    <div className="topbar-one__left">
                        <p>Welcome to Hariz Syakinah & CO, Torchbearers for Syariah & Law.</p>
                    </div>
                    <div className="topbar-one__right">
                        <a href="mailto:hsc.kanun@gmail.com" target="_blank"><i className="fas fa-envelope-open"></i>hsc.kanun@gmail.com</a>
                        <a href="https://www.waze.com/ul?q=HARIZ%20SYAKINAH%20&%20CO" target="_blank"><i className="fas fa-map-marker-alt"></i>No. 2, Jalan Solaris Mont Kiara, 50480, Kuala Lumpur.</a>
                        <div className="topbar-one__social">
                            {/* <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-google-plus-g"></a> */}
                            <a href="https://www.facebook.com/HarizSyakinahCo/" target="_blank" className="fab fa-facebook"></a>
                            <a href="https://instagram.com/hariz_syakinah_official?igshid=1d8zxdgmpo891" target="_blank" className="fab fa-instagram"></a>
                            {/* <a href="#" className="fab fa-youtube"></a> */}
                        </div>
                    </div>
                </div>
            </div>
            <header className="site-header site-header__header-one  ">
                <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
                    <div className="container clearfix">
                        
                        <div className="logo-box">
                            <NavLink onClick={handleClick} className="navbar-br/and" to="/">
                                <img src="assets/images/logo/hars_white-removebg(h70).png" className="main-logo m-3" alt="Awesome Image" />
                            </NavLink>
                            <button className="menu-toggler" data-target=".main-navigation" id='main_MenuNav' onClick={openMenu}>
                                <span className="fa fa-bars"></span>
                            </button>
                        </div>
                        
                        <div className="main-navigation" id='main_NavIgation'>
                            <ul className=" navigation-box ">
                                <li className={`${splitLocation [1] === "" ? "current" : ""}`}>
                                    <NavLink onClick={handleClick} to="/">Utama</NavLink>
                                </li>
                                <li className={`${splitLocation [1] === "about" ? "current" : ""}`}>
                                    <NavLink onClick={handleClick} to="/about">Tentang Kami</NavLink>
                                </li>

                                <li className={`${splitLocation [1] === "maintenance" ? "current" : ""}`}>
                                    <NavLink onClick={handleClick} to="/maintenance">Jelajah Kanun</NavLink>
                                </li>

                                <li className={`${splitLocation [1] === "service" ? "current" : ""}`}>
                                    <NavLink onClick={handleClick} to="/service">Khidmat Guaman</NavLink>
                                </li >
                                {/* <li>
                                    <NavLink href="blog-classNameic.html">News</NavLink>
                                    <ul>
                                        <li><a href="blog-classNameic.html">News classNameic</a></li>
                                        <li><a href="blog-grid.html">News Grid</a></li>
                                        <li><a href="blog-post.html">News Details</a></li>
                                    </ul>
                                </li> */}
                                <li className={`${splitLocation [1] === "contact" ? "current" : ""}`}>
                                    <NavLink onClick={handleClick} to="/contact">Hubungi Kami</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="right-side-box">
                            <a className="header-phone" href="tel:+6013-9764574"><i className="fas fa-phone-alt"></i>Hubungi: <span>+6013-9764574</span></a>
                            <a href="https://wa.me/60177164221?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn header-booking__btn">Temujanji</a>
                        </div>
                    </div>
                    
                </nav>
            </header>
        </div>
        </div>
      
        )
      }
      
      export default Header