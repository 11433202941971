import React from "react";
import Menu from '../layouts/Menu';
import {Link} from 'react-router-dom';
// import Footer from '../layouts/Footer';

function Hariz() {
    return (
        <div>
            <div className="page-wrapper">
                <Menu />

                <section
                    className="inner-banner inner-banner__overlay-two"
                    style={{
                        backgroundImage:
                            "url(assets/images/background/CustomBG3.png)",
                    }}
                >
                    <div className="container">
                        <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                        <h2 className="inner-banner__title">Tentang Kami</h2>
                        <ul className="list-unstyled thm-breadcrumb">
                            <li>
                                <a href="index.html">Utama</a>
                            </li>
                            <li>Tentang Pasukan Kami</li>
                        </ul>
                    </div>
                </section>

                <section className="team-one team-one__home-two">
                    <div className="container">
                        <div className="block-title text-center">
                            <h3 className="block-title__title">PASUKAN KAMI</h3>
                            <p className="block-title__text-two">
                            KAMI MEMPUNYAI PASUKAN PEGUAM YANG BERPENGALAMAN DALAM MENGENDALIKAN KES-KES MAHKAMAH DI MALAYSIA
                            </p>
                        </div>

                        {/* <div className="row no-gutters">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="team-one__single"></div>
                                </div> */}
                           
                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="team-one__single"></div>
                                </div>
                        </div> */}

                        <div className="row no-gutters">
                        {/* <div className="container">
                            <div className="image-box">
                                <img src={profile.imageUrl} alt="Profile" />
                            </div>
                            <div className="details-box">
                                <h2>Name</h2>
                                <p>Hariz</p>

                                <h2>Age</h2>
                                <p>29</p>

                                <h2>Location</h2>
                                <p>Johor</p>
                            </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                

            <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
                <i className="fa fa-angle-up"></i>
            </a>
        </div>
    </div>
    );
}

export default Hariz;
