import React from 'react';
import {NavLink} from 'react-router-dom';

const handleClick = () => {
    // force a re-render
    this.forceUpdate();
  };

function Footer() {
  return (
    <div>
<footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6">
                        <div className="footer-widget">
                            <NavLink onClick={handleClick} to="/" className="footer-widget__logo" style={{display:"block"}}><img src="assets/images/logo/hars_white-removebg(h70).png" alt="Awesome Image" className='mx-auto d-block text-center'/></NavLink>
                            <p className="footer-widget__text" style={{ textAlign: 'center' }}>Torchbearers for Syariah & Law.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="footer-widget">
                            <h3 className="footer-widget__title">Waktu Operasi Firma</h3>
                                <p className="footer-widget__text">
                                    <b>Isnin - Jumaat : 09:00 am - 05:00 pm</b>
                                </p>
                                <p className="footer-widget__text">
                                Kami boleh dihubungi selepas waktu operasi firma melalui <a href="https://wa.me/60177164221?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank"><b>whatsapp</b></a> untuk peguam kami membantu anda sesegera mungkin. Tertakluk kepada kelapangan peguam.
                                </p>
                        
                        </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-6">
                        <div className="footer-widget footer-widget__quick-link">
                            <h3 className="footer-widget__title">Quick Links</h3>
                            <ul className="footer-widget__links list-unstyled">
                                <li><NavLink to="/about">Tentang Firma Kami</NavLink></li>
                                <li><NavLink to="/about">Our Attorneys</NavLink></li>
                                <li><NavLink to="/contact">Hubungi Kami</NavLink></li>
                                <li><a href="https://wa.me/60177164221?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji.">Book Appointment</a></li>
                                <li><a href="#">Latest News</a></li>
                            </ul>
                        </div>
                    </div> */}

                    <div className="col-xl-4 col-lg-6">
                        <div className="footer-widget">
                            <h3 className="footer-widget__title">Hubungi Kami</h3>
                            <p className="footer-widget__text">
                                {/* <a href="https://www.waze.com/en/live-map/directions/my/wilayah-persekutuan-kuala-lumpur/kuala-lumpur/hariz-syakinah-and-co?place=ChIJH9fV9QBJzDERgQOJ_ivVaDs" target="_blank"><i className="fas fa-map-marker-alt"></i>&nbsp; */}
                                <a href="https://maps.app.goo.gl/un7EiCFbsZmUnwdE7" target="_blank"><i className="fas fa-map-marker-alt"></i>&nbsp;
                                    MESSRS. HARIZ SYAKINAH & CO, <br/> 
                                    THE MET CLUB by Colony, <br/>
                                    A-10-02, The MET Corporate Towers, <br/>
                                    No. 20, Jalan Dutamas 2, <br/>
                                    50480 Kuala Lumpur.
                                </a>
                            </p>
                            <p className="footer-widget__text footer-widget__contact-info"><i className="fa fa-phone-alt"></i><span>&nbsp;Hubungi: </span> <a href="tel:+6013-9764574">+6013-9764574</a></p>
                            <p className="footer-widget__text footer-widget__contact-info"><a href="https://wa.me/60177164221?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank"><i className="fab fa-whatsapp"></i>&nbsp;Tekan disini untuk whatssap</a></p>
                            <p className="footer-widget__text footer-widget__contact-info">
                            <i className="fa fa-envelope-open"></i>&nbsp;<span>Emel: </span>{" "}
                                <a href='mailto:hsc.kanun@gmail.com' target="_blank">hsc.kanun@gmail.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


        <div className="site-footer__bottom">
            <div className="container">
            <p>Copyright © {(new Date().getFullYear())} Hariz Syakinah & Co. All rights reserved. | <a href="https://vecode.com.my" target="_blank">VeCode</a></p>

                <div className="site-footer__social">
                    {/* <a href="#" className="fab fa-twitter"></a>
                    <a href="#" className="fab fa-google-plus-g"></a> */}
                    <a href="https://www.facebook.com/HarizSyakinahCo/" target="_blank" className="fab fa-facebook"></a>
                    <a href="https://instagram.com/hariz_syakinah_official?igshid=1d8zxdgmpo891" target="_blank" className="fab fa-instagram"></a>
                    {/* <a href="#" className="fab fa-youtube"></a> */}
                </div>
            </div>
        </div>
        </div>
      
        )
      }
      
      export default Footer