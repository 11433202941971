import React from 'react';

function Cases() {
  return (
    <div>
       <div className="content-wrapper">
         <div style={{height:"100vh"}}></div>
          </div>
    </div>
  )
}

export default Cases