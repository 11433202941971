import React from 'react';
import { NavLink } from "react-router-dom";

function Error() {
    const handleClick = () => {
        // force a re-render
        this.forceUpdate();
      };
  return (
    <section>
      <h2>Error 404</h2>
      <p>Page not found</p>
      <NavLink to={'/'}>back to Home</NavLink>
    </section>
  )
}

export default Error
