import React from 'react';
import Menu from '../layouts/Menu';
import {NavLink} from 'react-router-dom';
// import {} from '@flaticon/flaticon-uicons';

const handleClick = () => {
    // force a re-render
    this.forceUpdate();
  };

function Service() {
  return (
    <div>
       <div className="page-wrapper">
        <Menu />
        <section className="inner-banner">
            <div className="container">
                <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                <h2 className="inner-banner__title">Khidmat Guaman</h2>
                <ul className="list-unstyled thm-breadcrumb">
                    <li><NavLink onClick={handleClick} to="/">Utama</NavLink></li>
                    <li>Perkhidmatan Kami</li>
                </ul>
            </div>
        </section>
        <section className="case-two case-two__service-page-one">
            <div className="container">
                {/* <div className="block-title text-center">
                    <p className="block-title__text-two">Proident sunt culpa officia deserunt mollit animd laborum perspicia natus qui acusantium</p>
                </div>
                <p className="service-two__block-text text-center">Labore et dolore magna aliqu. Ut enim ad minim veniam, quis nostrud exercitation ullamco dui laboris nisi ut aliquip ex ea commodo consequat. <br/>Duis aute irure dolor reprehenderit kind voluptate velit esse cillum dolore fugiat nulla pariatur. Excepteur sint occaecat cupidatas non proident sunt <br/> culpa qui officia deserunt doloremque laudantium totam rem aperiam eaque ipsa quae ab illo.</p> */}

                <div className="case-two__box">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-handcuffs"></i>
                                    </div>
                                    <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Pertuduhan Jenayah</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-contract"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Sivil</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-mosque"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Syariah</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-car-accident"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Kemalangan</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-briefcase"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Korporat & Komersial</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-house"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Hartanah</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-money"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Tuntutan Hutang</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-police"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Tahanan Reman</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-work"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Perihal Kerja</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                {/* <div className="case-two__box-icon">
                                    <i className="opklim-judge"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Health Related Laws</a></h3>
                                    <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                <div className="case-two__box-icon">
                                    <i className="flaticon-rumor"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Fitnah</a></h3>
                                    {/* <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="case-two__box-single">
                                {/* <div className="case-two__box-icon">
                                    <i className="opklim-policy"></i>
                                </div>
                                <div className="case-two__box-content">
                                    <h3 className="case-two__box-title"><a href="#">Corporate Issues</a></h3>
                                    <p className="case-two__box-text">Sit amet, consectetur adipisicing elit por incididunt ut labore et dolore ma gna alin veniam, quis.</p>
                                    <a className="case-two__box-link" href="#"><span>More Details</span> <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        </div>
    </div>
  )
}

export default Service